import { FaEnvelope, FaPhone, FaWhatsapp, FaWeixin } from 'react-icons/fa';
import { ContactWrapper,
    ContactDescription,
    ContactHeading,
    ContactText,
    ContactIcon,
    ContactListItem,
    ContactList,
    ContactSection,
    ContactContainer,
    ContactLink } from './contactInfoElements';
    


const contactMethods = [
    {
      icon: <FaEnvelope />,
      text: 'braeden@f2atutoring.co.nz',
      type: 'email',
      value: 'braeden@f2atutoring.co.nz',
    },
    {
      icon: <FaPhone />,
      text: '(028) 417 6471',
      type: 'phone',
      value: '0284176471',
    },
    {
      icon: <FaWhatsapp />,
      text: '+64 28 417 6471',
      type: 'whatsapp',
      value: '+64284176471',
    },
    {
      icon: <FaWeixin />,
      text: 'WeChat',
      type: 'wechat',
      value: 'wxis_Ipzhxbpnoq7v22', // Replace 'your-wechat-id' with your actual WeChat ID or phone number
    },
  ];
  

  const handleClick = (contactMethod) => {
    const isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    switch(contactMethod.type) {
      case 'phone':
        window.location.href = 'tel:' + contactMethod.value;
        break;
      case 'email':
        window.location.href = 'mailto:' + contactMethod.value;
        break;
      case 'whatsapp':
        
        if (isMobile) {
          window.location.href = 'whatsapp://send?phone=' + contactMethod.value;
        } else {
          window.open('https://web.whatsapp.com/send?phone=' + contactMethod.value, '_blank');
        }
        break;
      case 'wechat':
        if (isMobile) {
          window.location.href = 'weixin://dl/chat?phone=' + contactMethod.value;
        } else {
          window.open('https://web.wechat.com/' + contactMethod.value, '_blank');
        }
        break;
      default:
        break;
    }
  }

export const homeObjOne = {
    id: 'Payment',
    lightBg: false,
    lightText: true,
    isContactPage:false,
    lightTextDesc: true,
    topLine:'Payment',
    headline:'Negotiable Rates',
    description:'Price is available upon request. We believe that a comfortable and supportive environment is essential for effective learning. That is why we offer negotiable prices for longer time slots or multiple students. Our experienced and dedicated tutors are committed to helping students achieve their academic goals and build confidence in their abilities. Minimum session duration is one hour.',
    buttonLabel:'Get Started',
    imgStart:true,
    img: require('../../images/Payment.svg').default,
    alt: 'exam',
    primary: true,
    darkText: false
}
export const homeObjTwo = {
    id: 'Subjects',
    lightBg: true,
    lightText: false,
    isContactPage:false,
    lightTextDesc: false,
    topLine:'Different Subjects we offer',
    headline:'Subjects ',
    description:'Experience academic excellence with our expert tutors! Achieve mastery in Chemistry, Physics, Calculus, Biology, English, Japanese, and Mandarin. All tutors hold relevant degrees and possess classroom and tutoring experience. Discover the perfect tutor to guide you towards success!',
    buttonLabel:'Get Started',
    imgStart:false,
    img: require('../../images/online.svg').default,
    alt: 'exam',
    primary: true,
    darkText: true
}
export const homeObjThree = {
    id: 'About',
    lightBg: false,
    lightText: true,
    isContactPage:false,
    lightTextDesc: true,
    topLine:'what we do',
    headline:'About us',
    description:'We are a team of experienced and qualified tutors dedicated to providing personalised and effective tutoring services to students of all ages and levels, conducted online for maximum flexibility and convenience.',
    buttonLabel:'Get Started',
    imgStart:true,
    img: require('../../images/teacher.svg').default,
    alt: 'exam',
    primary: true,
    darkText: false
}
export const contactObjectOne = {
    id: 'Contact',
    lightBg: false,
    lightText: true,
    isContactPage:true,
    lightTextDesc: true,
    headline:'Get in Touch',
    description:(
        <>
      <ContactSection lightBg={false}>
        <ContactContainer>
          <ContactWrapper>
            <ContactDescription>These are the best ways to contact us:</ContactDescription>
            <ContactList>
              {contactMethods.map(({ icon, text, link, type, value }) => (
                <ContactListItem key={text}>
                    <ContactLink href={link} onClick={() => handleClick({ type, value })} >
                        <ContactIcon >{icon}</ContactIcon>
                        <ContactText>{text}</ContactText>
                    </ContactLink>
                </ContactListItem>
              ))}
            </ContactList>
          </ContactWrapper>
        </ContactContainer>
      </ContactSection>
    </>
    ),
    
    imgStart:true,
    img: require('../../images/contactpic.svg').default,
    alt: 'exam',
    primary: true,
    darkText: false
}

