import React from 'react'
import { useState } from 'react'
import ContactForm from '../components/ContactMe'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import { heroObjTwo } from '../components/HeroSection/Data'
import InfoSection from '../components/InfoSection'
import { contactObjectOne } from '../components/InfoSection/Data'
import Footer from '../components/Footer'
import { navObjectContact } from '../components/Navbar/data'

const ContactPage= () => {
    const[isOpen, setIsOpen] =useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }


  return (
    <>
    
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar {...navObjectContact}/>
      <InfoSection {...contactObjectOne}/>
     
      <Footer/>
       
    </>
  )
}

export default ContactPage