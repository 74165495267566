import React from 'react'
import {Button} from '../ButtonElements'
import { InfoContainer,
    InfoRow,
    InfoWrapper,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    ImgWrap,
    Img,
    Heading,
    Subtitle,
    BtnWrap
} from './InfoElements'

const InfoSection  = ({
    lightBg,
    lightText,
    id,
    imgStart,
    topLine,
    headline,
    description,
    alt,
    darkText,
    buttonLabel,
    img,
    primary,
    isContactPage,
    dark,
    dark2
    }) => {
  return (
    <>
    <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper contactPage={isContactPage}>
            <InfoRow imgStart={imgStart}>
                <Column1>
                    <TextWrapper>
                        <TopLine>{topLine}</TopLine>
                        <Heading lightText ={lightText}>{headline}</Heading>
                        <Subtitle darkText ={darkText}>{description}</Subtitle>
                        {buttonLabel
                                    ?<BtnWrap>
                                        <Button to='contactme'
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={80}
                                        primary={primary ? 1: 0}>{buttonLabel}
                                        </Button>
                                    </BtnWrap>
                                    :<></>
                        }
                    </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={img} alt={alt}/>
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
    </InfoContainer>
    </>
  )
}

export default InfoSection 