import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big? '12px 48px':'12px 30px')};
  color: ${({dark})=>(dark? '#010606':'#fff')};
  font-size: ${({fontBig}) => (fontBig? '20px':'16px')};
  outline: none;
  border:none;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;


  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary? '#fff':'#01BF71')};
  }
  svg {
    fill: ${({dark})=>(dark? '#010606':'#fff')};
    width: 20px; /* adjust to your icon's size */
    height: 20px; /* adjust to your icon's size */
    margin-right: 10px; /* adjust to your desired spacing */
  }
  
`;

const sendWhatsappMessage = () => {
  const phoneNumber = '+64284176471'; // replace with your phone number
  const message = 'Hello! I would like to know more about your tutoring.'; // replace with your default message
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  const isWhatsAppSupported = /WhatsApp/.test(navigator.userAgent);
  if (isWhatsAppSupported) {
    window.open(url, '_blank');
  } else {
    alert('WhatsApp is not installed on your device');
  }
};

export const WhatsAppButton = (props) => {
  return (
    <Button onClick={sendWhatsappMessage} {...props}>
      {props.children}
    </Button>
  );
};

export const PhoneButton = ({ phoneNumber, children, ...props }) => {
    const handleClick = () => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (isMobile) {
        window.location.href = `tel:${phoneNumber}`;
      } else {
        const isGoogle = /google/i.test(navigator.userAgent);
        if (isGoogle) {
          window.open(`https://www.google.com/search?q=${phoneNumber}`, '_blank');
        }
      }
    };
  
    return (
      <Button onClick={handleClick} {...props}>
        {children}
      </Button>
    );
  };

  export const EmailButton = ({ emailAddress, children, ...props }) => {
    const handleClick = () => {
      const isMailToSupported = /mailto:/.test(navigator.userAgent);
      if (isMailToSupported) {
        window.location.href = `mailto:${emailAddress}`;
      } else {
        const provider = emailAddress.split('@')[1];
        window.open(`https://www.${provider}`, '_blank');
      }
    };
  
    return (
      <Button onClick={handleClick} {...props}>
        {children}
      </Button>
    );
  };