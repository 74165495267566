import React, { useState } from 'react';
import { SES } from 'aws-sdk';
import { Button } from 'react-scroll';
import contactus from '../../images/contactus.svg'
import { Container,
    Icon,
    FormWrap,
    FormContent,
    FormH1,
    FormButton,
    FormLabel,
    FormInput,
    FormInputMessage,
    Form,
    Text,
    Column1,
    Column2,
    InfoRow,
    Img
} from './ContactFormElements'



const ses = new SES({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                    region: process.env.REACT_APP_AWS_REGION });




const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      // Validate email address
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert('Please enter a valid email address.');
        return;
      }
  
      // Validate file size and type
      if (file && file.size > 5000000) {
        alert('File size is too large. Please upload a file that is smaller than 5MB.');
        return;
      }
      const fileTypeRegex = /(\.pdf|\.doc|\.docx|\.jpg|\.jpeg|\.png)$/i;
      if (file && !fileTypeRegex.test(file.name)) {
        alert('File type is not supported. Please upload a PDF, DOC, DOCX, JPG, JPEG, or PNG file.');
        return;
      }
      
      try {
        // Send email using AWS SES
        await ses.sendEmail({
        
          Destination: {
            ToAddresses: ['braeden@f2atutoring.co.nz']
          },
          Message: {
            Body: {
              Text: { Data: message }
            },
            Subject: { Data: subject }
          },
          Source: 'braeden@f2atutoring.co.nz',
          ReplyToAddresses: [email],
          ...(file && {
            // Attach file to email if a file was uploaded
            Message: {
              Body: {
                Html: {
                  Data: `
                    <html>
                      <body>
                        <p>${message}</p>
                        <p><b>Attachment:</b></p>
                        <p><a href="cid:${file.name}">${file.name}</a></p>
                      </body>
                    </html>
                  `
                }
              },
              Subject: { Data: subject },
              Attachments: [{
                Filename: file.name,
                Content: file,
                ContentType: file.type,
                ContentDisposition: 'attachment',
                ContentId: file.name
              }]
            }
          })
        }).promise();
        setConfirmationMessage('Email sent');
      } catch (error) {
        console.error(error);
        alert('An error occurred. Please try again later.');
      }
    };
  
    
  return (
    <>
        <Container>
            <FormWrap>
                <Icon to="/">F2A Tutoring</Icon>
                <InfoRow>
                    <Column1>
                        <FormContent>
                            <Form id="contact-form" onSubmit={handleSubmit}>
                                <FormH1>Contact Us</FormH1>
                                
                                <FormInput type="text" name="name" placeholder="Name" value={name} onChange={(event) => setName(event.target.value)} required />
                                <FormInput type="email" name="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} required />
                                <FormInput type="text" name="subject" placeholder="Subject" value={subject} onChange={(event) => setSubject(event.target.value)} required />
                                <textarea name="message" placeholder="Message" rows="5" value={message} onChange={(event) => setMessage(event.target.value)} required></textarea>                          
                                <FormButton type="submit">Send</FormButton>
                                {confirmationMessage && <p style={{ color: '#01bf71' }}>{confirmationMessage}</p>}
                            </Form>
                        </FormContent>
                    </Column1>
                    <Column2>
                    <Img src={contactus}/>
                    </Column2>
                </InfoRow>
            </FormWrap>
        </Container>
    </>
  )
}

export default ContactForm