import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './pages';
import ContactPage from './pages/contactme';



function App() {
  return (
    
      <Router>
        <Switch>
          <Route  path='/contactme' exact component={ContactPage}/>
          <Route  path='/' exact component={Home} />
          
        </Switch>
      </Router>
      

  );
}

export default App;

