 import React from 'react'
 import { SidebarContainer, 
    CloseIcon, 
    Icon, 
    SidebarWrapper, 
    SidebarLink, 
    SidebarMenu, 
    SideBtnWrap,
    SidebarRoute
} from './SidebarElements'
 
 const Sidebar = ({isOpen, toggle}) => {
   return (
     <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="About"
                smooth={true} 
                duration={500} 
                spy={true}
                exact='true' 
                offset={-80}
                activeClass='active'
                onClick={toggle}>About Us</SidebarLink>
                <SidebarLink to="Online"
                smooth={true} 
                duration={500} 
                spy={true}
                exact='true' 
                offset={-80}
                activeClass='active'
                onClick={toggle}>Online</SidebarLink>
                <SidebarLink to="Payment"
                smooth={true} 
                duration={500} 
                spy={true}
                exact='true' 
                offset={-80}
                activeClass='active'
                onClick={toggle}>Pricing</SidebarLink>
                <SideBtnWrap>
                  <SidebarRoute to="/contactme"> Contact Us </SidebarRoute>
                </SideBtnWrap>
            </SidebarMenu>
        </SidebarWrapper>
     </SidebarContainer>
   )
 }
 
 export default Sidebar