import React, {useState} from 'react'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/Data'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { heroObjOne } from '../components/HeroSection/Data'
import { navObjectHome } from '../components/Navbar/data'

const Home = () => {
    const[isOpen, setIsOpen] =useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }


  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle} {...navObjectHome}/>
    <HeroSection {...heroObjOne}/>
    <InfoSection {...homeObjThree}/>
    <InfoSection {...homeObjTwo}/>
    <InfoSection {...homeObjOne}/>
    
    <Footer/>
    
    </>
  )
}

export default Home