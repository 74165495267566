import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll'
import SvgIcon from '../../images/AM-Logo.svg';
import {Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon, 
  NavMenu, 
  NavItem, 
  NavLinks,
  NavBtn,
  NavBtnLink, 
  AMLogo} from './NavbarElements';
//comments

const Navbar = ({toggle, isContactPage } ) => {

  const [scrollNav, setScrollNav] =useState(false)

  const changeNav = ()=>{
    if(window.scrollY >= 80){
      setScrollNav(true)
    }else{
      setScrollNav(false)
    }
  }

  useEffect(()=> {
    window.addEventListener('scroll', changeNav)
  },[])

  const toggleHome = () =>{
    scroll.scrollToTop();
  };
  return (
    <>
      <IconContext.Provider value={{color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}> F2A Tutoring </NavLogo>
                <MobileIcon onClick={toggle}>
                  <FaBars/>
                </MobileIcon>
                {isContactPage
                            ? <></>
                            :<NavMenu>
                            <NavItem>
                              <NavLinks to='About'
                              smooth={true} 
                              duration={500} 
                              spy={true}
                              exact='true' 
                              offset={-80}
                              activeClass='active'>
                              About us</NavLinks>
                            </NavItem>
                            <NavItem>
                              <NavLinks to='Subjects'
                              smooth={true} 
                              duration={500} 
                              spy={true}
                              exact='true' 
                              offset={-80}
                              activeClass='active'>Subjects</NavLinks>
                            </NavItem>
                            <NavItem>
                              <NavLinks to='Payment'
                              smooth={true} 
                              duration={500} 
                              spy={true}
                              exact='true' 
                              offset={-80}
                              activeClass='active'>Pricing</NavLinks>
                            </NavItem>
                          </NavMenu>
                  }
  
                <NavBtn>
                  <NavBtnLink to="/contactme"> Contact Us </NavBtnLink>
                </NavBtn>
                <AMLogo href="https://www.aucklandmagazine.com/best-tutoring-spots-auckland" target="_blank" rel="noopener noreferrer">
                  <img src={SvgIcon} alt="SVG Icon" />
                </AMLogo>
            </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}



export default Navbar