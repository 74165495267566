import React, {useState} from 'react'
import Video from '../../videos/video.mp4'
import { Button, WhatsAppButton, PhoneButton, EmailButton } from '../ButtonElements'
import {ReactComponent as Whatsapp} from '../../images/whatsapp.svg'
import {ReactComponent as Email} from '../../images/emailicon.svg'
import {ReactComponent as Telephone} from '../../images/phoneicon.svg'
import { HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent, 
    HeroH1,
    HeroP, 
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    Img,
    ContactButtonWrapper} from './HeroElements'
import { FaWhatsapp } from 'react-icons/fa'

const HeroSection = ({id, lightBg, isVideo, heroImg, headline, description, buttonLabel, buttonLabel1, buttonLabel2}) => {

    const [hover, setHover]= useState(false)

    const onHover = () => {
        setHover(!hover)
    }
  return (
    <HeroContainer id={id} lightBg={lightBg}>
        <HeroBg>
            {isVideo
            ? <VideoBg autoPlay loop muted src={Video} type='video/mp4'></VideoBg>
            : <Img src= {heroImg}></Img>       
            }
            
        </HeroBg>
        <HeroContent>
            <HeroH1>{headline}</HeroH1>
            {description
            ?<>
                <HeroP>{description}</HeroP>
                <ContactButtonWrapper>
                    <WhatsAppButton to="/"
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}>
                        {buttonLabel} {hover ? <ArrowForward/>:<ArrowRight/>}

                        <span><Whatsapp/></span>
                    </WhatsAppButton>
                    <PhoneButton
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}>
                        {buttonLabel1} {hover ? <ArrowForward/>:<ArrowRight/>}

                        <span><Telephone/></span>
                    </PhoneButton>
                    <EmailButton
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}>
                        {buttonLabel2} {hover ? <ArrowForward/>:<ArrowRight/>}

                        <span><Email/></span>
                    </EmailButton>
                </ContactButtonWrapper>
            </>
            :<HeroBtnWrapper>
                <Button to='/contactme' 
                onMouseEnter={onHover} 
                onMouseLeave={onHover}>
                    {buttonLabel} {hover ? <ArrowForward/>:<ArrowRight/>}
                </Button>
            </HeroBtnWrapper>
         }
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection