import styled from "styled-components";
export const ContactSection = styled.section`
  background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
`;

export const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 0;
  height: 300px;
  max-height: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
`;

export const ContactHeading = styled.h1`
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};
  font-size: 48px;
  margin-bottom: 24px;
`;

export const ContactDescription = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom:30px;
`;

export const ContactList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ContactListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    color: #01bf71;
  }
`;

export const ContactIcon = styled.span`
  font-size: 2rem;
  margin-right: 16px;
`;

export const ContactText = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
`;


export const ContactLink = styled.a`
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#fff')};
  font-size: 1.5rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #01bf71;
    transform: translateY(-2px);
  }
`;