import React from 'react'
import { FooterContainer,
    FooterLinkItems,
    FooterLinksWrapper,
    FooterLinksContainer,
    FooterWrap,
    FooterLink,
    FooterLinkTitle,
    SocialIconLink,
    SocialLogo, 
    SocialIcons, 
    SocialMedia, 
    SocialMediaWrap,
    WebsiteRights} from './FooterElements'
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaWeixin} from 'react-icons/fa'
const Footer = () => {
  return (
    <>
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/">
                        F2A Tutoring
                    </SocialLogo>
                    <WebsiteRights>f2a Tutoring {new Date().getFullYear()} All rights reserved</WebsiteRights>
                    <SocialIcons>
                       
                        <SocialIconLink href="/" target="_blank" aria-label="Whatsapp">
                            <FaWhatsapp/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="WeChat">
                            <FaWeixin/>
                        </SocialIconLink>

                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
    </>
  )
}

export default Footer