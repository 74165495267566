export const heroObjOne = {
    id: 'home',
    lightBg: false,
    isVideo: true,
    headline:'TUTORING ONLINE. ANYWHERE. ANYTIME',
    description:null,
    buttonLabel:'Get Started',
    
}

export const heroObjTwo = {
    id: 'contactme',
    lightBg: true,
    isVideo: false,
    heroImg: require('../../images/contactpic.svg').default,
    headline:'Best way to contact us',
    description:"test",
    buttonLabel:'Whatsapp',
    buttonLabel1:'Phone',
    buttonLabel2:'Email',
    
}

